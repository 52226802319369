import _0 from "./assets/webp/home/events/1.webp";
import _1 from "./assets/webp/home/events/2.webp";
import _2 from "./assets/webp/home/events/3.webp";
import _3 from "./assets/webp/home/events/4.webp";
import _4 from "./assets/webp/home/events/5.webp";
import _5 from "./assets/webp/home/naturalism/1.webp";
import _6 from "./assets/webp/home/naturalism/2.webp";
import _7 from "./assets/webp/home/naturalism/3.webp";
import _8 from "./assets/webp/home/naturalism/4.webp";
import _9 from "./assets/webp/home/naturalism/5.webp";
import _10 from "./assets/webp/home/portraits/1.webp";
import _11 from "./assets/webp/home/portraits/2.webp";
import _12 from "./assets/webp/home/portraits/3.webp";
import _13 from "./assets/webp/home/portraits/4.webp";
import _14 from "./assets/webp/home/portraits/5.webp";
import _15 from "./assets/webp/home/reportages/1.webp";
import _16 from "./assets/webp/home/reportages/2.webp";
import _17 from "./assets/webp/home/reportages/3.webp";
import _18 from "./assets/webp/home/reportages/4.webp";
import _19 from "./assets/webp/home/reportages/5.webp";
import _20 from "./assets/webp/home/urban/1.webp";
import _21 from "./assets/webp/home/urban/2.webp";
import _22 from "./assets/webp/home/urban/3.webp";
import _23 from "./assets/webp/home/urban/4.webp";
import _24 from "./assets/webp/home/urban/5.webp";


export const files = {

 "directories": {


				"events": {
				"files": {

                        "1": {
					        "url": _0,
					        "name": "1"
                        }
				    ,

                        "2": {
					        "url": _1,
					        "name": "2"
                        }
				    ,

                        "3": {
					        "url": _2,
					        "name": "3"
                        }
				    ,

                        "4": {
					        "url": _3,
					        "name": "4"
                        }
				    ,

                        "5": {
					        "url": _4,
					        "name": "5"
                        }
				    ,
}
,

				},

			

				"naturalism": {
				"files": {

                        "1": {
					        "url": _5,
					        "name": "1"
                        }
				    ,

                        "2": {
					        "url": _6,
					        "name": "2"
                        }
				    ,

                        "3": {
					        "url": _7,
					        "name": "3"
                        }
				    ,

                        "4": {
					        "url": _8,
					        "name": "4"
                        }
				    ,

                        "5": {
					        "url": _9,
					        "name": "5"
                        }
				    ,
}
,

				},

			

				"portraits": {
				"files": {

                        "1": {
					        "url": _10,
					        "name": "1"
                        }
				    ,

                        "2": {
					        "url": _11,
					        "name": "2"
                        }
				    ,

                        "3": {
					        "url": _12,
					        "name": "3"
                        }
				    ,

                        "4": {
					        "url": _13,
					        "name": "4"
                        }
				    ,

                        "5": {
					        "url": _14,
					        "name": "5"
                        }
				    ,
}
,

				},

			

				"reportages": {
				"files": {

                        "1": {
					        "url": _15,
					        "name": "1"
                        }
				    ,

                        "2": {
					        "url": _16,
					        "name": "2"
                        }
				    ,

                        "3": {
					        "url": _17,
					        "name": "3"
                        }
				    ,

                        "4": {
					        "url": _18,
					        "name": "4"
                        }
				    ,

                        "5": {
					        "url": _19,
					        "name": "5"
                        }
				    ,
}
,

				},

			

				"urban": {
				"files": {

                        "1": {
					        "url": _20,
					        "name": "1"
                        }
				    ,

                        "2": {
					        "url": _21,
					        "name": "2"
                        }
				    ,

                        "3": {
					        "url": _22,
					        "name": "3"
                        }
				    ,

                        "4": {
					        "url": _23,
					        "name": "4"
                        }
				    ,

                        "5": {
					        "url": _24,
					        "name": "5"
                        }
				    ,
}
,

				},

			},
};